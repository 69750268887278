import 'keen-slider/keen-slider.min.css'

import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/Animation/FadeInUp'
import { Arrow } from 'app/components/Common/Arrow'
import { Close } from 'app/components/Common/Close'
import { Gallery } from 'app/components/Common/Gallery'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { MediaContextProvider } from 'app/theme/media'
import useLockedBody from 'app/utils/useLockedBody'
import { useKeenSlider } from 'keen-slider/react'
import React, { memo, useEffect, useState } from 'react'

interface ImagesProps {
  image?: ImageProps
}

export interface Props {
  images: ImagesProps[]
}

export const GallerySlider = memo(function GallerySlider({ images }: Props) {
  if (!images) {
    return null
  }
  if (images?.length < 1) {
    return null
  }

  const imagesForModal: ImageProps[] = []
  images.forEach((element) => {
    if (element.image) imagesForModal.push(element.image)
  })

  const [_locked, setLocked] = useLockedBody()
  const [modalGalleryStatus, setModalGalleryStatus] = useState(false)
  const [clickedSlide, setClickedSlide] = useState<any>(null)

  useEffect(() => {
    if (modalGalleryStatus) {
      document.addEventListener('keydown', function (e) {
        switch (e.keyCode) {
          case 27:
            setLocked(false)
            setModalGalleryStatus(false)
            setClickedSlide(0)
            break
        }
      })
    }
  }, [modalGalleryStatus])

  const [sliderRef, galleryRef] = useKeenSlider({
    loop: images.length > 1 ? true : false,
    drag: true,
    slides: {
      perView: 'auto',
      spacing: 30,
    },
    breakpoints: {
      '(max-width: 1199px)': {
        slides: {
          perView: 'auto',
          spacing: 20,
        },
      },
      '(max-width: 550px)': {
        slides: {
          perView: 'auto',
          spacing: 15,
        },
      },
    },
  })

  return (
    <>
      <MediaContextProvider>
        <SliderContainer>
          <FadeInUp>
            <Slider ref={sliderRef} className="keen-slider grab-cursor">
              {images.map((item, index) => (
                <Slide
                  className="keen-slider__slide"
                  key={index}
                  data-counter={index}
                  onClick={(e) => {
                    if (!modalGalleryStatus) {
                      setLocked(true)
                      setModalGalleryStatus(true)
                    } else {
                      setLocked(false)
                      setModalGalleryStatus(false)
                    }

                    const realClickedSlide = Number(
                      e.currentTarget.getAttribute('data-counter'),
                    )

                    setClickedSlide(realClickedSlide)
                    setTimeout(() => {
                      setClickedSlide(null)
                    }, 100)
                  }}
                >
                  <StyledImage {...item.image} />
                </Slide>
              ))}
            </Slider>
          </FadeInUp>
          {images.length > 2 ? (
            <Arrows row space="between">
              <Arrow
                onClick={(e) =>
                  e.stopPropagation() || galleryRef.current?.prev()
                }
              />
              <Arrow
                direction="R"
                onClick={(e) =>
                  e.stopPropagation() || galleryRef.current?.next()
                }
              />
            </Arrows>
          ) : null}
        </SliderContainer>
        <Modal className={modalGalleryStatus ? 'open' : undefined}>
          <Close
            onClick={() => {
              setLocked(false)
              setModalGalleryStatus(false)
              setClickedSlide(0)
            }}
          />
          <Gallery
            clickedSlide={clickedSlide}
            images={imagesForModal}
            isOpen={modalGalleryStatus}
          />
        </Modal>
      </MediaContextProvider>
    </>
  )
})

const Arrows = styled(FlexBox)`
  position: absolute;
  top: 50%;
  right: 0;
  left: -3.125rem;
  transform: translateY(-50%);
  z-index: 3;
  pointer-events: none;

  @media (max-width: 1199px) {
    left: -1.25rem;
  }
`

const SliderContainer = styled.div`
  position: relative;
  margin-top: 9.375rem;
  margin-left: calc(50% - 41.0625rem);
  margin-bottom: 1.06rem;

  @media (max-width: 1600px) {
    margin-left: 8.938vw;
  }

  @media (max-width: 1199px) {
    margin-top: 3.5rem;
    margin-left: 1.25rem;
    margin-bottom: 0;
  }
`

const Slider = styled.div`
  width: 100%;
`

const Slide = styled.div`
  max-width: 24.375rem;
  min-width: 24.375rem;
  max-height: 19.25rem;
  min-height: 19.25rem;

  @media (max-width: 1600px) {
  }

  @media (max-width: 1199px) {
    max-width: 21.875rem;
    max-height: 21.875rem;
    min-width: 21.875rem;
    min-height: 21.875rem;
  }

  @media (max-width: 550px) {
    max-width: 14.25rem;
    max-height: 14.25rem;
    min-width: 14.25rem;
    min-height: 14.25rem;
  }
`

const StyledImage = styled(Image)`
  position: relative;
  display: block;
  width: 100%;
  max-width: 24.375rem;
  aspect-ratio: 390/308;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

const Modal = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  opacity: 0;
  top: 0;
  left: 0;
  visibility: hidden;
  transition: 0.2s ease-out;
  z-index: -1;

  &.open {
    opacity: 1;
    visibility: visible;
    z-index: 9999;
  }
`
