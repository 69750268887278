import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/Animation/FadeInUp'
import { Button, Props as ButtonProps } from 'app/components/Common/Button'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { styles } from 'app/theme'
import React, { memo } from 'react'

export interface Props {
  cta?: ButtonProps
  description?: string
  image?: ImageProps
  imageSmall?: ImageProps
  label?: string
  list?: string[]
  title?: string
  variant?: Variant
}

export const Rooms = memo(function Rooms({
  cta,
  description,
  image,
  imageSmall,
  label,
  list,
  title,
  variant = 'default',
}: Props) {
  return (
    <Container variant={variant} id="rooms">
      {label ? (
        <input
          data-section="rooms"
          name="nodeRef"
          type="hidden"
          value={label}
        />
      ) : null}
      <Wrapper>
        <Left>
          {label ? (
            <FadeInUp>
              <Label>{label}</Label>
            </FadeInUp>
          ) : null}
          {title ? (
            <FadeInUp>
              <Title>{title}</Title>
            </FadeInUp>
          ) : null}
          {description ? (
            <FadeInUp>
              <Description
                variant={variant}
                dangerouslySetInnerHTML={{ __html: description }}
              />
            </FadeInUp>
          ) : null}
          {imageSmall ? <ImageSmall {...imageSmall} /> : null}
        </Left>
        <Right>
          {image ? <ImageBig {...image} /> : null}
          <Bottom>
            {list ? (
              <List>
                <FadeInUp>
                  {list.map((item: any, index) => (
                    <Item key={index}>
                      {item.title ? (
                        <Description variant="list">
                          <ItemTitle>{item.title}</ItemTitle>
                          {item.description ? ' ' + item.description : null}
                        </Description>
                      ) : null}
                    </Item>
                  ))}
                </FadeInUp>
              </List>
            ) : null}
            {cta ? (
              <FadeInUp>
                <StyledButton {...cta} />
              </FadeInUp>
            ) : null}
          </Bottom>
        </Right>
      </Wrapper>
    </Container>
  )
})

const Container = styled.section<ContainerProps>`
  margin: 9.375rem 8.938vw 0;
  padding-bottom: 9.375rem;

  @media (max-width: 1199px) {
    margin: 5rem 1.25rem 0;
    padding-bottom: 5rem;
  }
`

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 82.125rem;
  margin: 0 auto;

  @media (max-width: 992px) {
    flex-direction: column;
    justify-content: flex-start;
  }
`

const Left = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 45%;
  margin-right: 8.9375rem;

  @media (max-width: 1600px) {
    margin-right: 8.938vw;
  }

  @media (max-width: 1199px) {
    margin-right: 6.938vw;
  }

  @media (max-width: 992px) {
    align-items: center;
    width: 100%;
    margin-right: 0;
  }

  @media (max-width: 550px) {
    align-items: flex-start;
  }
`
const List = styled.div``

const Right = styled.div`
  display: flex;
  flex-direction: column;
  width: 55%;

  @media (max-width: 992px) {
    align-items: center;
    width: 100%;
    margin-top: 2.5rem;
  }

  @media (max-width: 550px) {
    display: block;
  }
`

const ImageBig = styled(Image)`
  position: relative;
  display: block;
  max-width: 49.0625rem;
  margin-right: -8.9375rem;
  aspect-ratio: 1;

  @media (max-width: 1600px) {
    width: calc(100% + 8.9375vw);
    margin-right: -8.9375vw;
  }

  @media (max-width: 1199px) {
    width: calc(100% + 1.25rem);
    margin-right: -1.25rem;
  }

  @media (max-width: 992px) {
    width: calc(100% + 2.5rem);
    margin-left: -1.25rem;
    margin-right: -1.25rem;
    margin-top: 5rem;
    order: 1;
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

const ImageSmall = styled(Image)`
  position: relative;
  display: block;
  max-width: 33.125rem;
  margin-top: 7.1875rem;
  aspect-ratio: 530/418;

  @media (max-width: 1440px) {
    margin-top: 6.25rem;
  }

  @media (max-width: 1199px) {
    margin-top: 3.125rem;
  }

  @media (max-width: 992px) {
    margin-right: 0;
    width: 100%;
    margin-top: 2.5rem;
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

const Bottom = styled.div`
  margin-left: 7.0625rem;
  margin-top: 6.25rem;

  @media (max-width: 992px) {
    margin-top: 0;
    margin-left: 0;
  }
`

const Item = styled.div``

const ItemTitle = styled.div`
  ${styles.description};
  font-weight: 600;
  display: inline;
`

const Label = styled.div`
  ${styles.label}
`

const Title = styled.h2`
  ${styles.h2}
  max-width: 37.5rem;

  @media (max-width: 992px) {
    max-width: 49.0625rem;
    text-align: center;
  }

  @media (max-width: 550px) {
    max-width: 100%;
    text-align: left;
  }
`

const Description = styled.div<ContainerProps>`
  ${styles.description}
  max-width: 26.75rem;
  margin-right: 6.25rem;
  margin-top: 2.5rem;

  @media (max-width: 1600px) {
    margin-right: 6.25vw;
  }

  @media (max-width: 1440px) {
    margin-right: 0;
  }

  @media (max-width: 992px) {
    max-width: 49.0625rem;
    text-align: center;
  }

  @media (max-width: 550px) {
    max-width: 100%;
    text-align: left;
  }

  ${({ variant }) => {
    switch (variant) {
      case 'short':
        return css`
          max-width: 21.6875rem;

          @media (max-width: 992px) {
            max-width: 49.0625rem;
          }

          @media (max-width: 550px) {
            max-width: 100%;
          }
        `
      case 'list':
        return css`
          margin-top: 0;
        `
    }
  }}
`

const StyledButton = styled(Button)`
  margin-top: 3.4375rem;

  @media (max-width: 1199px) {
    margin-top: 2.5rem;
  }

  @media (max-width: 550px) {
    max-width: 100%;
  }
`

interface ContainerProps {
  variant: Variant
}

export type Variant = 'default' | 'short' | 'list'
