import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/Animation/FadeInUp'
import { Button, Props as ButtonProps } from 'app/components/Common/Button'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { styles } from 'app/theme'
import React, { memo } from 'react'
import { LazyLoadComponent } from 'react-lazy-load-image-component'

export interface Props {
  description?: string
  image?: ImageProps
  imageSmall?: ImageProps
  label?: string
  title?: string
  variant?: Variant
}

export const WideGarden = memo(function WideGarden({
  description,
  image,
  imageSmall,
  label,
  title,
  variant = 'default',
}: Props) {
  return (
    <Container id="garden">
      {label ? (
        <input
          data-section="garden"
          name="nodeRef"
          type="hidden"
          value={label}
        />
      ) : null}
      <Wrapper>
        {label ? (
          <FadeInUp>
            <Label>{label}</Label>
          </FadeInUp>
        ) : null}
        {title ? (
          <FadeInUp>
            <Title>{title}</Title>
          </FadeInUp>
        ) : null}
        {image ? (
          <LazyLoadComponent>
            <ImageBig {...image} />
          </LazyLoadComponent>
        ) : null}
        <Bottom>
          <Inner variant={variant}>
            {description ? (
              <FadeInUp>
                <Description
                  dangerouslySetInnerHTML={{ __html: description }}
                />
              </FadeInUp>
            ) : null}
          </Inner>
          {imageSmall ? <ImageSmall {...imageSmall} variant={variant} /> : null}
        </Bottom>
      </Wrapper>
    </Container>
  )
})

const Container = styled.section`
  margin: 9.375rem 8.938vw 0;

  @media (max-width: 1199px) {
    margin: 5rem 1.25rem 0;
  }
`

const Wrapper = styled.div`
  width: 100%;
  max-width: 82.125rem;
  margin: 0 auto;

  @media (max-width: 992px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  @media (max-width: 550px) {
    align-items: flex-start;
  }
`

const Label = styled.div`
  ${styles.label}
`

const Title = styled.h2`
  ${styles.h2}
  max-width: 46.25rem;

  @media (max-width: 992px) {
    max-width: 49.0625rem;
    text-align: center;
  }

  @media (max-width: 550px) {
    max-width: 100%;
    text-align: left;
  }
`

const ImageBig = styled(Image)`
  position: relative;
  display: block;
  width: calc(100% + 8.9375rem);
  height: 66.666vh;
  max-height: 37.5rem;
  margin-top: 3.125rem;
  margin-left: -8.9375rem;

  @media (max-width: 992px) {
    width: calc(100% + 2.5rem);
    margin-top: 2.5rem;
    margin-left: -1.25rem;
    margin-right: -1.25rem;
  }

  @media (max-width: 550px) {
    max-height: 23.4375rem;
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

const Bottom = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 6.25rem;

  @media (max-width: 1199px) {
    margin-top: 3.125rem;
  }

  @media (max-width: 992px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-top: 2.5rem;
  }

  @media (max-width: 550px) {
    align-items: flex-start;
  }
`

const ImageSmall = styled(Image)<ContainerProps>`
  position: relative;
  display: block;
  width: 43%;
  max-width: 33.125rem;
  aspect-ratio: 530/418;
  order: 2;

  @media (max-width: 992px) {
    width: 100%;
    margin-top: 2.5rem;
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  ${({ variant }) => {
    switch (variant) {
      case 'inverted':
        return css`
          order: 1;
        `
    }
  }}
`

const Inner = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  width: 57%;
  max-width: 26.75rem;
  margin-top: 2.5rem;
  margin-right: 8.9375rem;
  order: 1;

  @media (max-width: 1440px) {
    margin-top: 0;
  }

  @media (max-width: 1199px) {
    margin-top: 2.5rem;
    margin-right: 6.9375rem;
  }

  @media (max-width: 992px) {
    align-items: center;
    max-width: 100%;
    width: 100%;
    margin-top: 0;
    margin-right: 0;
    margin-left: 0;
  }

  @media (max-width: 550px) {
    display: block;
  }

  ${({ variant }) => {
    switch (variant) {
      case 'inverted':
        return css`
          margin-right: 0;
          margin-left: 8.9375rem;
          order: 2;

          @media (max-width: 1199px) {
            margin-right: 0;
            margin-left: 6.9375rem;
          }

          @media (max-width: 992px) {
            width: 100%;
            margin-right: 0;
            margin-left: 0;
            order: 1;
          }
        `
    }
  }}
`

const Description = styled.div`
  ${styles.description}

  @media (max-width: 992px) {
    max-width: 49.0625rem;
    text-align: center;
  }

  @media (max-width: 550px) {
    max-width: 100%;
    text-align: left;
  }
`

interface ContainerProps {
  variant: Variant
}

export type Variant = 'default' | 'inverted'
