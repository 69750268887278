import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/Animation/FadeInUp'
import { Button, Props as ButtonProps } from 'app/components/Common/Button'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { styles } from 'app/theme'
import React, { memo } from 'react'
import { LazyLoadComponent } from 'react-lazy-load-image-component'

export interface Props {
  cta?: ButtonProps
  description?: string
  image?: ImageProps
  label?: string
  title?: string
  variant?: Variant
}

export const Wide = memo(function Wide({
  cta,
  description,
  image,
  label,
  title,
  variant = 'default',
}: Props) {
  return (
    <Container id="wide">
      {label ? (
        <input data-section="wide" name="nodeRef" type="hidden" value={label} />
      ) : null}
      <Wrapper>
        <Inner>
          {label ? (
            <FadeInUp>
              <Label>{label}</Label>
            </FadeInUp>
          ) : null}
          {title ? (
            <FadeInUp>
              <Title>{title}</Title>
            </FadeInUp>
          ) : null}
          {description ? (
            <FadeInUp>
              <Description dangerouslySetInnerHTML={{ __html: description }} />
            </FadeInUp>
          ) : null}
          {cta ? (
            <FadeInUp>
              <StyledButton {...cta} />
            </FadeInUp>
          ) : null}
        </Inner>
        {image ? (
          <LazyLoadComponent>
            <ImageBig {...image} />
          </LazyLoadComponent>
        ) : null}
      </Wrapper>
    </Container>
  )
})

const Container = styled.section`
  margin: 0 8.938vw 0;

  @media (max-width: 1199px) {
    margin: 0 1.25rem 0;
  }
`

const Wrapper = styled.div`
  width: 100%;
  max-width: 82.125rem;
  margin: 0 auto;

  @media (max-width: 992px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  @media (max-width: 550px) {
    align-items: flex-start;
  }
`

const Inner = styled.div`
  margin-right: 2.2rem;
  max-width: 50.925rem;
`

const Label = styled.div`
  ${styles.label}

  @media (max-width: 992px) {
    justify-content: center;
  }
`

const Title = styled.h2`
  ${styles.h2}
  max-width: 46.25rem;

  @media (max-width: 992px) {
    max-width: 49.0625rem;
    text-align: center;
  }

  @media (max-width: 550px) {
    max-width: 100%;
    text-align: left;
  }
`

const ImageBig = styled(Image)`
  position: relative;
  display: block;
  width: calc(100% + 8.9375rem);
  height: 66.666vh;
  max-height: 37.5rem;
  margin-top: 3.125rem;
  margin-right: -8.9375rem;

  @media (max-width: 1600px) {
    width: calc(100% + 8.9375vw);
    margin-right: -8.9375vw;
  }

  @media (max-width: 1199px) {
    margin-right: -1.25rem;
    width: calc(100% + 1.25rem);
  }

  @media (max-width: 992px) {
    width: calc(100% + 2.5rem);
    margin-top: 2.5rem;
    margin-left: -1.25rem;
    margin-right: -1.25rem;
  }

  @media (max-width: 550px) {
    max-height: 23.4375rem;
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

const Description = styled.div`
  ${styles.description}
  margin-top: 1.6875rem;

  @media (max-width: 992px) {
    max-width: 49.0625rem;
    text-align: center;
  }

  @media (max-width: 550px) {
    max-width: 100%;
    text-align: left;
  }
`

const StyledButton = styled(Button)`
  margin-top: 3.4375rem;

  @media (max-width: 1199px) {
    margin-top: 2.5rem;
  }

  @media (max-width: 550px) {
    max-width: 100%;
  }
`

interface ContainerProps {
  variant: Variant
}

export type Variant = 'default' | 'inverted'
