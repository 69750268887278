import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/Animation/FadeInUp'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { styles } from 'app/theme'
import React, { memo } from 'react'

export interface Props {
  image?: ImageProps
  imageSmall?: ImageProps
  label?: string
  title?: string
  variant?: Variant
}

export const Gallery = memo(function Gallery({
  image,
  imageSmall,
  label,
  title,
  variant = 'default',
}: Props) {
  return (
    <Container variant={variant} id="gallery">
      {label ? (
        <input
          data-section="gallery"
          name="nodeRef"
          type="hidden"
          value={label}
        />
      ) : null}
      <Wrapper>
        <Left>
          {label ? (
            <FadeInUp>
              <Label>{label}</Label>
            </FadeInUp>
          ) : null}
          {title ? (
            <FadeInUp>
              <Title>{title}</Title>
            </FadeInUp>
          ) : null}
          {imageSmall ? <ImageSmall {...imageSmall} /> : null}
        </Left>
        <Right>{image ? <ImageBig {...image} /> : null}</Right>
      </Wrapper>
    </Container>
  )
})

const Container = styled.section<ContainerProps>`
  margin: 0 8.938vw 0;

  @media (max-width: 1199px) {
    margin: 0 1.25rem 0;
  }
`

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 82.125rem;
  margin: 0 auto;

  @media (max-width: 992px) {
    flex-direction: column;
    justify-content: flex-start;
  }
`

const Left = styled.div`
  display: flex;
  flex-direction: column;
  width: 45%;

  @media (max-width: 992px) {
    align-items: center;
    width: 100%;
  }

  @media (max-width: 550px) {
    align-items: flex-start;
  }
`

const Right = styled.div`
  display: flex;
  flex-direction: column;
  width: 55%;
  margin-top: 9.875rem;
  margin-left: 8.9375rem;

  @media (max-width: 1600px) {
    margin-left: 8.938vw;
  }

  @media (max-width: 1199px) {
    margin-left: 6.938vw;
    margin-top: 3.5rem;
  }

  @media (max-width: 992px) {
    align-items: center;
    width: 100%;
    margin-left: 0;
    margin-top: 2.5rem;
  }

  @media (max-width: 550px) {
    display: block;
  }
`

const ImageBig = styled(Image)`
  position: relative;
  display: block;
  max-width: 49.0625rem;
  margin-right: -8.9375rem;
  aspect-ratio: 1;

  @media (max-width: 1600px) {
    width: calc(100% + 8.9375vw);
    margin-right: -8.9375vw;
  }

  @media (max-width: 1199px) {
    margin-right: -1.25rem;
    width: calc(100% + 1.25rem);
  }

  @media (max-width: 992px) {
    width: calc(100% + 2.5rem);
    margin-top: 2.5rem;
    margin-left: -1.25rem;
    margin-right: -1.25rem;
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

const ImageSmall = styled(Image)`
  position: relative;
  display: block;
  max-width: 33.125rem;
  margin-top: 7.1875rem;
  aspect-ratio: 530/418;

  @media (max-width: 1440px) {
    margin-top: 6.25rem;
  }

  @media (max-width: 1199px) {
    margin-top: 3.125rem;
  }

  @media (max-width: 992px) {
    width: 100%;
    margin-top: 2.5rem;
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

const Label = styled.div`
  ${styles.label}
`

const Title = styled.h2`
  ${styles.h2}
  max-width: 37.5rem;

  @media (max-width: 992px) {
    max-width: 49.0625rem;
    text-align: center;
  }

  @media (max-width: 550px) {
    max-width: 100%;
    text-align: left;
  }
`

interface ContainerProps {
  variant: Variant
}

export type Variant = 'default' | 'short'
