import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/Animation/FadeInUp'
import React, { memo } from 'react'

export interface Props {
  children: React.ReactNode
  title?: string
  variant?: Variant
}

export const Background = memo(function Background({
  children,
  title,
  variant = 'default',
}: Props) {
  return (
    <>
      {title ? (
        <FadeInUp>
          <Title>{title}</Title>
        </FadeInUp>
      ) : null}
      <Children variant={variant}>
        {children}
        <Container>
          <Wrapper />
        </Container>
      </Children>
    </>
  )
})

const Children = styled.div<ChildrenProps>`
  position: relative;
  margin-top: 9.375rem;
  padding: 8.125rem 0 9.5rem;

  @media (max-width: 1199px) {
    margin-top: 5rem;
    padding: 5rem 0;
  }

  ${({ variant }) => {
    switch (variant) {
      case 'top':
        return css`
          margin-top: -1.19rem;

          @media (max-width: 1199px) {
            margin-top: -1.19rem;
          }

          @media (max-width: 992px) {
            margin-top: -0.83rem;
          }
        `
    }
  }}
`

const Container = styled.div`
  position: absolute;
  top: 0;
  right: calc(50% - 41.0625rem);
  height: 100%;
  width: 100vw;
  background-color: ${({ theme }) => theme.colors.variants.neutralLight3};
  z-index: -1;

  @media (max-width: 1600px) {
    right: calc(50% - 41.0625vw);
  }

  @media (max-width: 1199px) {
    right: 2.1875rem;
  }
`

const Title = styled.div`
  font-size: 14.4375rem;
  line-height: 12.5rem;
  color: ${({ theme }) => theme.colors.variants.neutralLight3};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  max-width: 82.125rem;
  width: 100%;
  margin: 5.4rem auto 0;

  @media (max-width: 1439px) {
    font-size: 9.4375rem;
    line-height: 9.2rem;
  }

  @media (max-width: 1199px) {
    margin: 5.4rem 1.25rem 0;
    width: calc(100% - 2.5rem);
  }

  @media (max-width: 992px) {
    font-size: 6.4375rem;
    line-height: 6.2rem;
  }

  @media (max-width: 550px) {
    font-size: 3.4375rem;
    line-height: 3.9rem;
  }
`

const Wrapper = styled.div`
  width: 100%;
  max-width: 82.125rem;
  margin: 0 auto;
`

interface ChildrenProps {
  variant: Variant
}

export type Variant = 'default' | 'top'
