import * as React from 'react'
import { memo } from 'react'
const SvgChevronLeft = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="39.586"
    height="30.414"
    viewBox="0 0 39.586 30.414"
  >
    <g
      id="Raggruppa_7008"
      data-name="Raggruppa 7008"
      transform="translate(-116.103 -8639.793)"
    >
      <g id="freccia" transform="translate(58.811 -138)">
        <line
          id="Linea_31"
          data-name="Linea 31"
          x2="38.379"
          transform="translate(58 8793)"
          fill="none"
          stroke="#242424"
          strokeLinecap="round"
          strokeWidth="1"
        />
        <line
          id="Linea_32"
          data-name="Linea 32"
          y1="14.501"
          x2="14.5"
          transform="translate(58 8778.5)"
          fill="none"
          stroke="#242424"
          strokeLinecap="round"
          strokeWidth="1"
        />
        <line
          id="Linea_33"
          data-name="Linea 33"
          x2="14.5"
          y2="14.499"
          transform="translate(58 8793.001)"
          fill="none"
          stroke="#242424"
          strokeLinecap="round"
          strokeWidth="1"
        />
      </g>
    </g>
  </svg>
)
const Memo = memo(SvgChevronLeft)
export default Memo
