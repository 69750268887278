import * as React from 'react'
import { memo } from 'react'
const SvgChevronRight = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="39.586"
    height="30.414"
    viewBox="0 0 39.586 30.414"
  >
    <g id="freccia" transform="translate(-30.311 -34.793)">
      <line
        id="Linea_31"
        data-name="Linea 31"
        x1="38.379"
        transform="translate(30.811 50)"
        fill="none"
        stroke="#242424"
        strokeLinecap="round"
        strokeWidth="1"
      />
      <line
        id="Linea_32"
        data-name="Linea 32"
        x1="14.5"
        y1="14.501"
        transform="translate(54.689 35.5)"
        fill="none"
        stroke="#242424"
        strokeLinecap="round"
        strokeWidth="1"
      />
      <line
        id="Linea_33"
        data-name="Linea 33"
        x1="14.5"
        y2="14.499"
        transform="translate(54.689 50.001)"
        fill="none"
        stroke="#242424"
        strokeLinecap="round"
        strokeWidth="1"
      />
    </g>
  </svg>
)
const Memo = memo(SvgChevronRight)
export default Memo
