import styled from '@emotion/styled'
import { ChevronLeft, ChevronRight } from 'app/components/Icons'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'

export interface Props {
  direction?: 'L' | 'R'
  disabled?: boolean
  onClick?: (e: any) => void
}

export const Arrow = memo(function Arrow({
  direction = 'L',
  disabled,
  onClick,
}: Props) {
  return (
    <Container
      dial={5}
      className={disabled ? 'disabled' : undefined}
      onClick={onClick}
    >
      {direction === 'L' ? <ChevronLeft /> : <ChevronRight />}
    </Container>
  )
})

const Container = styled(FlexBox)`
  width: 6.25rem;
  height: 6.25rem;
  background: ${({ theme }) => theme.colors.variants.primaryLight};
  cursor: pointer;
  pointer-events: all;
  transition: background 0.25s ease-in-out;

  &.disabled {
    pointer-events: none;
  }

  svg {
    width: auto;
    height: 1.8125rem;
    stroke: ${({ theme }) => theme.colors.variants.primaryDark};
  }
`
