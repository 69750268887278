import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/Animation/FadeInUp'
import { Button, Props as ButtonProps } from 'app/components/Common/Button'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { styles } from 'app/theme'
import React, { memo } from 'react'
import { isMobile } from 'react-device-detect'

export interface Props {
  cellphone?: string
  description?: string
  email?: string
  image?: ImageProps
  label?: string
  phone?: string
  title?: string
  variant?: Variant
}

export const Contacts = memo(function Contacts({
  cellphone,
  description,
  email,
  image,
  label,
  phone,
  title,
  variant = 'default',
}: Props) {
  return (
    <Container variant={variant} id="contacts">
      {label ? (
        <input
          data-section="contacts"
          name="nodeRef"
          type="hidden"
          value={label}
        />
      ) : null}
      <Wrapper>
        <Left>
          {label ? (
            <FadeInUp>
              <Label>{label}</Label>
            </FadeInUp>
          ) : null}
          {title ? (
            <FadeInUp>
              <Title>{title}</Title>
            </FadeInUp>
          ) : null}
          {description ? (
            <FadeInUp>
              <Description
                variant={variant}
                dangerouslySetInnerHTML={{ __html: description }}
              />
            </FadeInUp>
          ) : null}
          <Items>
            {phone ? (
              <Item>
                <Icon
                  src="/icon_phone.svg"
                  alt="Phone"
                  width="30"
                  height="30"
                />
                <Info
                  aria-label="link"
                  href={'tel:' + phone}
                  rel="noopener"
                  target="_blank"
                  onClick={() => {
                    if (isMobile) {
                      typeof window.gtag !== 'undefined' &&
                        window.gtag('event', 'Click', {
                          event_category: 'Website',
                          event_label: 'Phone Number',
                        })
                    }
                  }}
                >
                  {phone}
                </Info>
              </Item>
            ) : null}
            {cellphone ? (
              <Item>
                <Icon
                  src="/icon_cellphone.svg"
                  alt="Phone"
                  width="30"
                  height="30"
                />
                <Info
                  aria-label="link"
                  href={'tel:' + cellphone}
                  rel="noopener"
                  target="_blank"
                  onClick={() => {
                    if (isMobile) {
                      typeof window.gtag !== 'undefined' &&
                        window.gtag('event', 'Click', {
                          event_category: 'Website',
                          event_label: 'Phone Number',
                        })
                    }
                  }}
                >
                  {cellphone}
                </Info>
              </Item>
            ) : null}
            {email ? (
              <Item>
                <Icon
                  src="/icon_email.svg"
                  alt="Email"
                  width="30"
                  height="30"
                />
                <Info
                  aria-label="link"
                  href={'mailto:' + email}
                  rel="noopener"
                  target="_blank"
                  onClick={() => {
                    typeof window.gtag !== 'undefined' &&
                      window.gtag('event', 'Click', {
                        event_category: 'Website',
                        event_label: 'Email Address',
                      })
                  }}
                >
                  {email}
                </Info>
              </Item>
            ) : null}
          </Items>
        </Left>
        <Right>{image ? <ImageBig {...image} /> : null}</Right>
      </Wrapper>
    </Container>
  )
})

const Container = styled.section<ContainerProps>`
  margin: 9.375rem 8.938vw 0;

  @media (max-width: 1199px) {
    margin: 5rem 1.25rem 0;
  }
`

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 82.125rem;
  margin: 0 auto;

  @media (max-width: 992px) {
    flex-direction: column;
    justify-content: flex-start;
  }
`

const Left = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 45%;
  margin-right: 8.9375rem;

  @media (max-width: 1600px) {
    margin-right: 8.938vw;
  }

  @media (max-width: 1199px) {
    margin-right: 6.938vw;
  }

  @media (max-width: 992px) {
    align-items: center;
    width: 100%;
    margin-right: 0;
  }

  @media (max-width: 550px) {
    align-items: flex-start;
  }
`

const Right = styled.div`
  display: flex;
  flex-direction: column;
  width: 55%;

  @media (max-width: 992px) {
    align-items: center;
    width: 100%;
    margin-top: 2.5rem;
  }

  @media (max-width: 550px) {
    display: block;
  }
`

const ImageBig = styled(Image)`
  position: relative;
  display: block;
  max-width: 49.0625rem;
  margin-right: -8.9375rem;
  aspect-ratio: 1;

  @media (max-width: 1600px) {
    width: calc(100% + 8.9375vw);
    margin-right: -8.9375vw;
  }

  @media (max-width: 1199px) {
    width: calc(100% + 1.25rem);
    margin-right: -1.25rem;
  }

  @media (max-width: 992px) {
    width: calc(100% + 2.5rem);
    margin-left: -1.25rem;
    margin-right: -1.25rem;
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

const Items = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  flex-wrap: wrap;
  margin-top: 3.125rem;

  @media (max-width: 992px) {
    justify-content: center;
    max-width: 31.25rem;
  }

  @media (max-width: 550px) {
    justify-content: flex-start;
  }
`

const Item = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 2.5rem;

  @media (max-width: 992px) {
    align-items: center;
  }

  @media (max-width: 550px) {
    align-items: flex-start;
    width: 100%;
  }
`

const Icon = styled.img`
  margin-right: 1.25rem;
`

const Label = styled.div`
  ${styles.label}
`

const Title = styled.h2`
  ${styles.h2}
  max-width: 37.5rem;

  @media (max-width: 992px) {
    max-width: 49.0625rem;
    text-align: center;
  }

  @media (max-width: 550px) {
    max-width: 100%;
    text-align: left;
  }
`

const Info = styled.a`
  color: ${({ theme }) => theme.colors.variants.primaryDark};
  font-family: ${({ theme }) => theme.fontFamily.label};
  font-weight: 600;
  font-size: 0.8125rem;
  letter-spacing: 0.1em;
  line-height: 1.125rem;
  text-transform: uppercase;

  @media (max-width: 992px) {
    text-align: center;
  }

  @media (max-width: 550px) {
    text-align: left;
  }
`

const Description = styled.div<ContainerProps>`
  ${styles.description}
  max-width: 26.75rem;
  margin-right: 6.25rem;
  margin-top: 2.5rem;

  @media (max-width: 1600px) {
    margin-right: 6.25vw;
  }

  @media (max-width: 1440px) {
    margin-right: 0;
  }

  @media (max-width: 992px) {
    max-width: 49.0625rem;
    text-align: center;
  }

  @media (max-width: 550px) {
    max-width: 100%;
    text-align: left;
  }

  ${({ variant }) => {
    switch (variant) {
      case 'short':
        return css`
          max-width: 21.6875rem;

          @media (max-width: 992px) {
            max-width: 49.0625rem;
          }

          @media (max-width: 550px) {
            max-width: 100%;
          }
        `
    }
  }}
`

interface ContainerProps {
  variant: Variant
}

export type Variant = 'default' | 'short'
